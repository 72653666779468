import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/styles.scss'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import { Icon } from '@iconify/vue2';
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Icon',Icon);

// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
