<template>
  <div class="_overflow-x-hidden">
    <div class="home _bg-black _z-10  _relative _max-w-full">
      <div class="_opacity-50 _absolute _left-0 _top-0 _right-0 _z-30 _w-full _h-full">        
        <div class="swiper swiper-hero _h-full">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
                <img :src="require('@/assets/img/slide/1.png')" class="_w-full _h-full _object-cover " />
            </div>
            <div class="swiper-slide">
                <img :src="require('@/assets/img/slide/2.png')" class="_w-full _h-full _object-cover " />
            </div>
            <div class="swiper-slide">
                <img :src="require('@/assets/img/slide/3.png')" class="_w-full _h-full _object-cover " />
            </div>
          </div>
          
        </div>
        <!-- <img :src="require('@/assets/img/hero1.png')" class="_w-full _h-full _object-cover " /> -->
      </div>
      <div class="swiper-pagination main-swiper-pagination"></div>

      <div class="container _relative _flex  _min-h-[800px] _z-40">
        <social-floating/>
        <div class="_relative _z-50 _m-auto _flex _gap-5 _justify-center _items-center">
          <h1 class="_text-white _text-right _font-bold _text-[45px] _font-gilmer-bold _drop-shadow-[2px_4px_4px_rgba(0,0,0,0.45)]">
            Reggy Pratama <br/>
            <span class="_text-primary">Advertising</span>
          </h1>
          <p class="_text-left _font-gilmer-regular _text-white _text-[18px] _mx-auto _max-w-[594px] _mb-0">
            PT. Reggy Pratama Advertising (RPA) bergerak di bidang
            jasa periklanan sejak 2005 dan telah menangani berbagai 
            penyelenggaraan media promosi di area Jabodetabek, 
            Jawa Barat, Kalimantan, Sumatera, dan sejumlah wilayah 
            lainnya di Indonesia
          </p>
        </div>
      </div>
    </div>
    <section class="_relative _overflow-hidden">
      <!-- <div class="_absolute _top-[400px] _-left-[80px] _z-10">
        <img :src="require('@/assets/img/home/square1.svg')" class="_rotate-90 _block _top-0 _right-0" />
        <img :src="require('@/assets/img/home/square2.svg')" class="_rotate-90 _block _-mt-[219px] _mr-[25px] _bottom-0 _left-0" />
      </div> -->
      
      <div class="container _relative _z-20">
        <div class="_grid _grid-cols-12 _gap-3 _py-[10px]  _border _border-solid _border-gray-300 _rounded-xl _items-center _mt-4 _mb-10">
          <div class="_col-span-12 lg:_col-span-2 _p-[5px] _text-left">
            
            <div class="_font-gilmer-medium _text-muted _text-base _text-center">
              Partner <br/> Kami
            </div>
          </div>
          <div class="_col-span-12 lg:_col-span-10">
            <div class="swiper slide-brand
              [&>.button-navigation]:_absolute 
              [&>.button-navigation]:_top-[50%] 
              [&>.button-navigation]:_-translate-y-[50%] 
              [&>.button-navigation]:_z-[30]
              [&>.button-nav-disabled]:_hidden            
              ">
              <!-- Additional required wrapper -->
              <div class="swiper-wrapper">
                <!-- Slides -->
                <div class="swiper-slide">
                  <img  src="https://s3-alpha-sig.figma.com/img/cc8c/47e8/643e1079a0b98db45f811001834fc1f6?Expires=1697414400&Signature=dr-95fDNQUpJFHXyhXugApl3ttFillPeQQBdGJ4tlF2HJYOOfkFFkQNWHXcUCQ0j8BX58WIfKSKSK~dXcenMdXNOfYjZp1nqwznoKhD3VsIX38ormtebRGo7SDT4BZVXvF8eGZgRA-J6Lemd92K38PvI9QrARiCucphS~7dvkPd8MNQgWwSO8G4w2fquiRSVSYyggFT7kjXaBmp3rje5AyqKwzIlJBASoIJCZexg6uiPY3D6g8Ngr8x0kSyjwUObu2Ki6KxAbgDcPRgzVeKjdh7bA35U~UCBg2GA2AB9~k~U9DQeM-fT6tfNVw-9gB8dCS8lFzkY25kTgRfmRJDJ8A__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" class="_h-[100px] _object-contain _px-5 _w-full _object-center"/>
                </div>
                <div class="swiper-slide">
                  <img  src="https://s3-alpha-sig.figma.com/img/9780/469d/85f1c5999669db409cc995a147ec14a7?Expires=1697414400&Signature=IExHuXzEYpCabyFTluj2f8cjIfGKf-j2hDusKdEODicx6ipLF9f1LDH9cq6Jtv~0QlhdU8f-ut4DheGFhI-nD7qRcRbGAAF02JqqmXqdE7iNch77reQX2RYQ0-5d1F5X5ASTFYGW57w3b~DQONXMB1R51qSLVstYSVz4BI004yO6DxLmeMXF4S1Yox3FRNrgRjOZUKMwqUAlx~UkIXdsVNhI9qAstN8u4f8bUYcWJ9-hhICtiG3NT~ub~lItEBmUt6910UGJvZsezOO5xI7SpNTGMRH89kWxlslRULZRMvelhJzdD2xiDLT22HZv~76HMQjkeinsC2GSONRpOli84g__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" class="_h-[100px] _object-contain _px-5 _w-full _object-center"/>
                </div>
                <div class="swiper-slide">
                  <img  :src="require('@/assets/img/lotus-jasmine.svg')" class="_h-[100px] _object-contain _p-5 _w-full _object-center"/>
                </div>
                <div class="swiper-slide">
                  <img  src="https://s3-alpha-sig.figma.com/img/e6e1/0104/cbe76692488e87576753c392dad1fe8d?Expires=1697414400&Signature=NH6F0ehI5ALIdEdA5hujUPjXndh8bfNr5w276ifFp6jAhBoaOxTaNd3IhH2ehR7sc6ZK62q49eKo-ZvIbO8FNUopV1lZndMcDv87gGHNnKlsFgVYXxD9GIQHz3unZF5UE60vR6gR5KjN4g-IHkmGBW7nODI8ZuD6yZi9Xurx9oiL271p4y~wKgQg27EcU3-Rf7rALckVLqxyIVIdVYv-vf7~Z9FSfmECzJu2eKehJhWj9WiVLUnKFU8M8uwDf56SJm-ubDzt6A5S6nsIE9HuKL3XgV79oZUP9XdgxdQbfhULfSwlmeYWCjZW4JFVwfzSH2ukfshbOfBfNUR5rwPYcw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" class="_h-[100px] _object-contain _px-5 _w-full _object-center"/>
                </div>

            
              </div>
              <!-- If we need pagination -->

              <!-- If we need navigation buttons -->
              <!-- <div class="button-navigation button-prev _left-0">
                <img src="@/assets/img/left-arrow.svg" />
              </div>
              <div class="button-navigation button-next _right-0">
                <img src="@/assets/img/right-arrow.svg" />
                
              </div> -->

              <!-- If we need scrollbar -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
        <div class="_py-[34px] _flex _flex-col lg:_flex-row _items-center _gap-5">
          <div class="_flex _flex-col lg:_flex-row _items-center _gap-5">
            <div class="
              _font-gilmer-bold _text-[#335253] _text-5xl _relative _z-2 _mr-10              
              before:_content-['Purpose']
              before:_text-[100px]
              before:[text-shadow:_-1px_0_rgba(0,0,0,.5),_0_1px_rgba(0,0,0,.5),_1px_0_rgba(0,0,0,.5),_0_-1px_rgba(0,0,0,.5)]
              before:_z-[-1]
              lg:before:_left-[-50px]              
              before:_bottom-0
              before:_relative
              before:_text-white
            ">
              <span class="_ml-[-277px]">Purpose</span>
            </div>
            <div class="_max-w-[450px] _text-left">
              Merancang pesan-pesan strategis secara kredibel
              dalam menjangkau para pelanggan klien dengan
              melibatkan teknologi. 
            </div>
          </div>
          <hr class="_grow _border-[3px] _border-[#B66146] _hidden lg:_block" />
          <!-- <div class="_grid _grid-cols-12 _gap-[54px] _w-full">
            <div class="_col-span-12 lg:_col-span-4">
              <img :src="require('@/assets/img/home/1.png')" />
            </div>
            <div class="_col-span-12 lg:_col-span-8 _text-left ">
              <h2 class="_font-gilmer-bold _text-[48px] _mb-[20px]">Tentang <span class="_text-primary ">Kami</span> ?</h2>
              <p class="_text-2xl _font-gilmer-regular _leading-[39.99px] _mb-[40px]">
                <strong class="_font-gilmer-bold">REGGY PRATAMA</strong> Advertising adalah perusahaan yang bergerak dibidang periklanan yang meliputi promosi dalam bentuk media luar ruang, media dalam ruang dan berbagai Promosi lainnya.

              </p>
              <p class="_text-2xl _font-gilmer-regular _leading-[39.99px]">

                <strong class="_font-gilmer-bold">REGGY PRATAMA</strong> Advertising berdiri sejak tahun 2005, dan telah menyelenggarakan pembangunan media luar ruang di wilayah DKI Jakarta, Jawa Barat, Kalimantan, Sumatera dan di beberapa wilayah lain di Indonesia.
              </p>
            </div>
          </div> -->  
        </div>
        <div class="_py-[34px] _flex _flex-col lg:_flex-row _items-center lg:_justify-end _gap-5 _mb-10">
          <hr class="_grow _max-w-[184px] _border-[3px] _border-[#B66146]" />        
          <div class="_flex _flex-col lg:_flex-row _items-center _gap-5 lg:_ml-[100px]">
            <div class="
              _font-gilmer-bold _text-[#335253] _text-5xl _relative _z-2              
              before:_content-['Values']
              before:_text-[100px]
              before:[text-shadow:_-1px_0_rgba(0,0,0,.5),_0_1px_rgba(0,0,0,.5),_1px_0_rgba(0,0,0,.5),_0_-1px_rgba(0,0,0,.5)]
              before:_z-[-1]
              before:_left-[-50px]              
              before:_bottom-0
              before:_relative
              before:_text-white
            ">
              <span class="_ml-[-277px]">Values</span>
            </div>
            <div class="_max-w-[450px] _text-left">
              Karya-karya yang kami rancang secara personal
              dan profesional bagi para klien, berlandaskan pada nilai
              kepuasan pelanggan (customer satisfaction).
              RPA memiliki tim yang solid dengan pengalaman dan
              jaringan luas di sejumlah kota di Indonesia yang 
              memastikan
              terpenuhinya kebutuhan promosi para klien.
            </div>
          </div>
        </div>
        
        
      </div>
    </section>
   
    <section class="_relative">
      
      <div class="container _relative _z-20">
        <div class="_relative _rounded-2xl _mb-10">
          <img :src="require('@/assets/img/dart.jpg')" alt="" class="_absolute _object-cover _object-[center_72%] _w-full _h-full _top-0 _left-0 _right-0 _bottom-0 _rounded-2xl _z-5">
          <div class="_py-[40px] _px-[20px] lg:_px-14 _flex _items-center _font-gilmer-bold _text-black _text-[30px] lg:_text-[47px] _relative _z-10">
            Goals
          </div>
        </div>
        
        
        <slider-view-5 class="xl:_mb-[50px]"/>
       
      </div>
    </section>
    <section class="_relative">
      <div class="container">
        <div class="_relative _rounded-2xl _mb-10">
          <img :src="require('@/assets/img/meetout.jpg')" alt="" class="_absolute _object-cover _object-[center_61%] _w-full _h-full _top-0 _left-0 _right-0 _bottom-0 _rounded-2xl _z-5">
          <div class="_py-[40px] _px-[20px] lg:_px-14 _flex _items-center _font-gilmer-bold _text-white _text-[30px] lg:_text-[47px] _relative _z-10">
            Meet out tools
          </div>
        </div>
        <div class="_mb-10">
            <h3 class="_text-center _mb-5 lg:_text-4xl _font-gilmer-bold">RDMS</h3>
            <p class="_font-sans _max-w-[900px] _mx-auto lg:_text-2xl">
              Keputusan yang tepat dan bernas amatlah penting dalam proses berkarya kami. Oleh karena itu, kami melakukan inovasi melalui tools RDMS untuk menajamkan pengetahuan kami ihwal pasar yang relevan bagi para klien. RDMS membantu proses ini melalui pengumpulan data dalam mengorkestrasi dinamika pasar dan mengoptimalkan waktu serta harga yang tepat.
            </p>
        </div>
      </div>
    </section>
    <section class="_relative">
      <div class="container">
        <div class="_relative _rounded-2xl _mb-10">
          <img :src="require('@/assets/img/lamp.jpg')" alt="" class="_absolute _object-cover _object-[center_61%] _w-full _h-full _top-0 _left-0 _right-0 _bottom-0 _rounded-2xl _z-5">
          <div class="_py-[40px] _px-[20px] lg:_px-14 _flex _items-center _font-gilmer-bold _text-white _text-[30px] lg:_text-[47px] _relative _z-10 _text-left">
            Our creative <br/>
            meaning-making
          </div>
        </div>
        <div class="_mb-10">
            <h3 class="_text-center _mb-5 lg:_text-4xl _font-gilmer-bold">RDMS</h3>
            <p class="_font-sans _max-w-[900px] _mx-auto lg:_text-2xl _mb-5">
              Keputusan yang tepat dan bernas amatlah penting dalam proses berkarya kami. Oleh karena itu, kami melakukan inovasi melalui tools RDMS untuk menajamkan pengetahuan kami ihwal pasar yang relevan bagi para klien. RDMS membantu proses ini melalui pengumpulan data dalam mengorkestrasi dinamika pasar dan mengoptimalkan waktu serta harga yang tepat.
            </p>
            <h3 class="_text-center _mb-5 lg:_text-4xl _font-gilmer-bold">Monitoring</h3>
            <p class="_font-sans _max-w-[900px] _mx-auto lg:_text-2xl _mb-5">
              Mengevaluasi dan meningkatkan performa OOH 
Media campaign anda melalui data-driving monitoring.
              Pesan-pesan produk klien dirancang melalui proses
end-to-end analytical guna
              meningkatkan strategi 
penyampaian pesan disertai rekomendasi yang relevan. 
            </p>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// import SlideView3 from '@/components/SlideView3.vue';

// import SliderView4 from '@/components/SliderView4.vue';
import SocialFloating from '@/components/SocialFloating.vue';
import { getClient,dataCover } from '@/api/strapi';
import SliderView5 from '@/components/SliderView5.vue';


export default {
  name: 'HomeView',
  components: { SliderView5, SocialFloating},
  data: () => ({
    dataClients: [],
    pageClients: 1,
    pageSizeClients: 30,
    btn_moreClients: {
      show: true,
      disabled: false,
    },
    loading: {
      dataclients: true,
      // categories: true,
      btn_more: false,
    },
    titleDescription: "",
    titleDescription2: "",
    titleDescription3: "",
    titleDescription4: "",
    titleDescription5: "",
    aboutUsText: "",
    kelebihan: [
      {
        src: require('@/assets/img/home/item1.jpg'),
        text: `Drive <br/> <span>Thrue</span>`,
      },
      {
        src: require('@/assets/img/home/item2.jpg'),
        text: `Menjaring <br/> <span>Calon Konsumen</span>`,
      },
      {
        src: require('@/assets/img/home/item3.jpg'),
        text: `Efektif <br/> <span>dan Efisien</span>`,
      },
      {
        src: require('@/assets/img/home/item4.jpg'),
        text: `Simple`,
      },
      {
        src: require('@/assets/img/home/item4.jpg'),
        text: `Media <br/> <span>Pendukung</span>`,
      },
    ]
  }),
  // eslint-disable-next-line vue/no-unused-components
  methods: {
    async getData(whattodo) {
      await getClient({
        populate: '*',
        pagination: {
          page: this.pageClients,
          pageSize: this.pageSizeClients
        }
      }).then((res)=>{whattodo(res)})
    },
    async setClient() {
      await this.getData((res)=>{
        const {data} = res.data
        if(data) {
          
           const currentData = data.map((data)=>({
            
            clientName        : data.attributes.ClientName,          
            image         : dataCover(data.attributes.Image),
            // linkURL : data.attributes.LinkURL,
            
          }))
          this.dataClients = currentData
          console.log(this.dataClients)
          this.loading.dataclients = false

        }
      })
    }
  },
  mounted() {
    this.setClient().then(()=>{
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper('.slide-brand', {
        // Optional parameters
        slidesPerView: 5,
        breakpoints: {
          0: {
            slidesPerView: 3
          },
          992: {
            slidesPerView: 5
          
          },
        },
        loop: false,
        // If we need pagination
      
      
        // Navigation arrows
        navigation: {
          disabledClass: 'button-nav-disabled',
          nextEl: '.slide-brand .button-next',
          prevEl: '.slide-brand .button-prev',
        },

        // And if we need scrollbar
     
      });
    })

    window.addEventListener('scroll', this.handleScroll)
    // eslint-disable-next-line no-unused-vars
    const swiperMain = new Swiper('.swiper-hero', {
      // Optional parameters
      autoplay: {
        delay: 3000,
        disableOnInteraction: true,
      },
      pagination: {
        el: ".main-swiper-pagination",
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      slidesPerView: 1,
      loop: false,
      watchSlidesProgress: true,
      direction: 'horizontal',
      autoHeight: false,

      // If we need pagination
      // pagination: {
      //   el: '.swiper-pagination',
      // },
      
        
      // Navigation arrows
  

      // And if we need scrollbar
     
    });
    // init Swiper:
    // eslint-disable-next-line no-unused-vars
    
  },
  
}
</script>
<style lang="scss">
  .main-swiper-pagination {
    // left: -50% !important;
    @apply _absolute _bottom-[30px] _z-[100];
    .swiper-pagination-bullet {
      @apply _bg-white _opacity-100;
    }
    .swiper-pagination-bullet-active {
      @apply _bg-primary;
    }
  }
</style>djp