<template>
    <div class="_absolute _left-[50%] _-translate-x-[50%] lg:_translate-x-0 lg:_left-0 lg:_top-[50%] lg:_-translate-y-[50%] _flex lg:_flex-col _gap-3 _z-20"
    :class="($route.name !== 'Client' || $route.name !== 'Link') ? '_bottom-[120px]' :'_bottom-[50px]'"
    >
      <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
        <Icon icon="ic:baseline-facebook" class="_m-auto _text-2xl" />
      </a>
      <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
        <Icon icon="mdi:instagram" class="_m-auto _text-2xl" />
      </a>
      <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
        <Icon icon="akar-icons:linkedin-v2-fill" class="_m-auto _text-2xl" />
      </a>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>