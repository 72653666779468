<template>
  <div id="app">
    
    <nav class="navbar navbar-expand-lg _transition-all _fixed _w-full _z-50 hover:_bg-black"  :data-bs-theme="!view.topOfPagedark ? 'dark' : 'light'"  :class="{ '_bg-black': !view.topOfPage, '_bg-transparent': $route.name == 'Home' && view.topOfPage}">
      <div class="container _relative">
        
        <a class="navbar-brand _w-[88px] xl:_w-[120px]" href="#">
          <!-- <img v-if=" !view.topOfPage" :src="require('@/assets/img/logo-black.png')" alt="" class="_w-[88px]"/> -->
          <img :src="require('@/assets/img/logo2.svg')" alt="" class="_w-full"/>
          
        </a>
        <button class="navbar-toggler _border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <Icon icon="ri:menu-4-fill" color="white" width="40" />
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav _mx-auto mb-2 mb-lg-0 _gap-5 _text-lg _font-gilmer-medium [&>li>a]:_text-[16px]  [&>li.active>a]:_text-primary">
            <template v-for="(item,id) in menu" >
              <li v-if="!item.sublink" class="nav-item" :class="{'active': active_page == item.link}" :key="id">

                <a class="nav-link hover:_text-primary/80 _text-white" aria-current="page" :href="$router.resolve({ name: item.link }).href" >{{item.name}}</a>
              </li>
              <li v-else class="nav-item dropdown" :key="'drop-'+id"  :class="{'active': active_page == item.link}">

                <a class="nav-link hover:_text-primary/80 _text-white dropdown-toggle" aria-current="page" href="#" data-bs-toggle="dropdown">{{item.name}}</a>
                <ul class="dropdown-menu">
                  <li v-for="(sub,ids) in item.sublink" :key="ids">
                    <a class="nav-link hover:_text-primary/80 _text-white !_px-[15px]" :class="{'active': active_page == sub.sub_link}" aria-current="page" :href="$router.resolve({ name: item.link, params: { location: sub.sub_link} }).href" >{{ sub.sub_name }}</a>
                  </li>

                </ul>
              </li>
            </template>
            
            <!-- <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li> -->
            <!-- <li class="nav-item">
              <a class="nav-link disabled">Disabled</a>
            </li> -->
          </ul>
          <div class="d-flex _self-center lg:_max-w-[40px] xl:_max-w-[100px]" role="search">
            <div class="_relative _text-white _hidden lg:_flex _items-center _gap-3 _cursor-pointer" @click="toggleSearchMethod">
              <!-- <input class="form-control _pl-[40px] _pr-0  _bg-transparent _border-0 _shadow-none _text-[14px] _rounded-none focus:_shadow-none focus:_text-white focus:_bg-transparent placeholder:_text-white" type="search" placeholder="Search" aria-label="Search"> -->
              <Icon icon="tabler:search" class="_text-white" width="24"/>
              <span class="_hidden lg:_inline">Search</span>
            </div>
            <div class="search-menu" :class="{'active': toggleSearch == false}">
              <form refs="formSearch">
                <div class="_relative _text-white ">
                  <input ref="searchInput" v-model="searchInputVal" class="
                    form-control 
                    form-control-lg 
                    _pl-[40px] 
                    _bg-transparent
                    _border-gray-300
                    lg:_h-[70px] 
                    lg:_pl-[70px] 
                    lg:_pr-[70px] 
                    lg:_bg-black/60 
                    lg:_border-0 
                    _shadow-none 
                    _text-[14px]
                    lg:_text-[18px] 
                    _rounded-xl 
                    focus:_shadow-none 
                    _text-white 
                    focus:_text-white 
                    focus:_bg-black  
                    placeholder:_text-white
                  " type="search" placeholder="Search" aria-label="Search">
                  <Icon icon="tabler:search" class="_text-white _absolute _left-[10px] _top-[10px] lg:_left-[15px] lg:_top-[20px] lg:_w-[30px] _w-[24px]" />
                  <span class="_absolute _right-[10px] _top-[20px] _cursor-pointer _hidden lg:_inline" @click="toggleSearch = !toggleSearch">Close Search</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
    <section class="
      _bg-[url('@/assets/img/home/contact-email.jpg')] 
      _bg-no-repeat 
      _bg-cover 
      _bg-top 
      xl:_py-57 
      _py-20 
      _relative
      before:_content-['']
      before:_w-full
      before:_h-full
      before:_absolute
      before:_z-20
      before:_top-0
      before:_left-0
      before:_right-0
      before:_bottom-0
      before:_bg-[rgba(0,0,0,.65)]
      before:_block
    ">
      <div class="container _relative _z-30">
        <h2 class="_font-gilmer-bold xl:_text-5xl _mb-3 _text-white">
          Mau Coba <span class="_text-primary">Sekarang ?</span>
        </h2>
        <p class="xl:_text-lg _font-gilmer-regular _max-w-[1012px] _mx-auto _text-white _mb-5">
          Pengalaman kami dalam penyelenggaraan media luar ruang menjadi bekal kami dalam kepuasan pelayanan mulai dari penentuan titik strategis hingga pelayanan purna jual sebagai bagian dari orientasi kami dalam mengedepankan kepuasan konsumen.
        </p>
        <div class="_max-w-[743px] _flex _gap-[30px] _mx-auto">
          <input type="text" class="form-control form-control-lg xl:_h-[92px] _bg-white/50 _border-transparent _rounded-2xl placeholder:_text-white" placeholder="Alamat Email">
          <button class="_flex-none btn btn-primary btn-lg _text-lg xl:_w-[233px] xl:_h-[92px] _rounded-2xl">Kirim</button>
        </div>
      </div>
    </section>
    <footer>
      <div class="container">
        <div class="_grid _grid-cols-4 _py-[84px] _gap-5 lg:_gap-1">
          <div class="_order-3 lg:_order-1 _col-span-12 lg:_col-span-2 lg:_text-left">
            <img :src="require('@/assets/img/logo2-black.svg')" class="_mb-5" />
            <div class="_font-gilmer-regular _text-[#807D7D] _mb-5">
              Jl. Bangka Raya No.97, Kemang – Jakarta <br/> Selatan 12720, Jakarta – Indonesia
            </div>
            <div class="_flex _justify-center lg:_justify-start _gap-3">
              <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
                <Icon icon="ic:baseline-facebook" class="_m-auto _text-2xl" />
              </a>
              <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
                <Icon icon="mdi:instagram" class="_m-auto _text-2xl" />
              </a>
              <a href="#" class="_w-[48px] _h-[48px] _bg-[#E8E8EA] _flex _p-0 _rounded-full _text-[#646464]">
                <Icon icon="akar-icons:linkedin-v2-fill" class="_m-auto _text-2xl" />
              </a>
            </div>
          </div>
          <div class="_order-1 lg:_order-2 _flex _col-span-4 lg:_col-span-1 _flex-col _gap-[14px] _font-gilmer-regular _text-[20px] _text-left">
            <div class="_font-DMSans _font-bold _text-[#444]">
              RPA
            </div>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              About
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Price
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Product
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Contact
            </a>
          </div>
          <div class="_order-21 lg:_order-3 _flex _col-span-4 lg:_col-span-1 _flex-col _gap-[14px] _font-gilmer-regular _text-[20px] _text-left">
            <div class="_font-DMSans _font-bold _text-[#444]">
              Product
            </div>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Mini Billboard
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Giant Billboard
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Neon Box
            </a>
            <a href="#" class="_text-[#807D7D] _no-underline hover:_underline">
              Landmark
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  // import function to register Swiper custom elements
  // register Swiper custom elements


  // new Swiper('.swiper', {
  //   slidesPerView: 3,
  //   grid: {
  //     rows: 3,
  //   },
  //   mousewheel: {
  //     forceToAxis: true,
  //   },
  // });
  export default {
    // eslint-disable-next-line vue/no-unused-components
    metaInfo: {
      title: 'RPA',
    },
    data: () => ({
        view: {
        topOfPage: true
      },
      toggleSearch: true,
      toggleNav: false,
      searchInputVal: '',
      active_page: 'Home',
      menu: [
        {
          link: 'Home',
          name: 'Home',
        },
        {
          link: 'AboutUs',
          name: 'About Us',
        },
        {
          link: 'ProductNService',
          name: 'Product & Service',
        },
        {
          name: 'Available Site',
          link: 'AvailableSite',
          sublink: [
            {
              sub_name: 'Jabodetabek',
              sub_link: 'jabodetabek'
            },
            {
              sub_name: 'Jawa Barat',
              sub_link: 'jawa-barat'
            },
          ],
        },
        {
          link: 'Client',
          name: 'Client',
        },
        {
          link: 'ContactUs',
          name: 'Contact',
        },
        {
          link: 'Link',
          name: 'Link',
        },
      ],
    }),
    beforeMount() {
      window.addEventListener('scroll', this.handleScroll)
      this.active_page = this.$route.name
    },
    mounted() {
      console.log(this.active_page)
    },
    watch: {
      $route (to) {
        this.toggleNav = false
        this.active_page = to.name
        console.log(this.active_page)
      
        
      }
       
    },
    methods: {
      handleScroll(){
        if(window.pageYOffset>0){
          if(this.view.topOfPage) this.view.topOfPage = false
        } else {
          if(!this.view.topOfPage) this.view.topOfPage = true
        }
      },
      toggleSearchMethod() {
        this.toggleSearch = !this.toggleSearch
        this.searchInputVal = '';
        setTimeout(()=>{
          this.$refs.searchInput.focus()
        },10)
        
      },
    },
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.search-menu {
  position: fixed;
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;
  width: 50%;
  visibility: hidden;
  opacity: 0;
  z-index: 100;
  @apply _transition-all;
  &.active {
    visibility: visible;
    opacity: 1;
  }
  @media (max-width: 991px) {
    opacity: 1;
    visibility: visible;
    width: 90%;
    position: relative;
    top: 0;
    
  }
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
