import api from './api';
import qs from 'qs';
// const strapiAPI = process.env.VUE_APP_STRAPI_URL;
export const getAboutUs = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/about-us?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getArticles = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/articles?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getCategories = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/article-categories?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getFAQ = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/faqs?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getFAQCategories = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/faq-categories?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getFeaturedArticles = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/featured-article?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getMainContactAddress = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/main-contact-address?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getProductService = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/product-services?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getAvailableSite = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/available-sites?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getClient = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/clients?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getLink = async (config) => {
    try {
        let query
        if(config) {
            query = qs.stringify(config, {
              encodeValuesOnly: true, // prettify URL
            })            
        } else {
            query = null
        }
        const response = await api
            .get('/links?'+query);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const dataCover = (data,size = "thumbnail") =>{
  const url = process.env.VUE_APP_BACKEND_URL
  const img = data.data.attributes.formats[size].url
  return url+img
}